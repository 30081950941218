import { RouteRecordRaw } from 'vue-router';

const LoginPage = () => import(/* webpackChunkName: "page-login" */ '@/views/pages/Login/Login.vue');
const RegisterPage = () => import(/* webpackChunkName: "page-register" */ '@/views/pages/Register/Register.vue');
const TimelinePage = () => import(/* webpackChunkName: "page-timeline" */ '@/views/pages/Timeline/Timeline.vue');
const Page404 = () => import(/* webpackChunkName: "page-404" */ '@/views/pages/Page404/Page404.vue');
const OpenDisclaimer = () => import(/* webpackChunkName: "page-disclaimer" */ '@/views/pages/OpenDisclaimer/OpenDisclaimer.vue');
const RelativesListPage = () => import(/* webpackChunkName: "page-relatives-list" */ '@/views/pages/RelativesList/RelativesList.vue');
const NewPatient = () => import(/* webpackChunkName: "page-new-patient" */ '@/views/pages/NewPatient/NewPatient.vue');
const YieldPatientPage = () => import(/* webpackChunkName: "page-yield-patient" */ '@/views/pages/YieldPatient/YieldPatient.vue');

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: '/relatives-list',
    },
    {
        path: '/disclaimer',
        name: 'page-disclaimer',
        component: OpenDisclaimer,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/login',
        name: 'page-login',
        component: LoginPage,
    },
    {
        path: '/register',
        name: 'page-register',
        component: RegisterPage,
    },
    {
        path: '/relatives-list',
        name: 'page-relatives-list',
        component: RelativesListPage,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/timeline',
        name: 'page-timeline',
        component: TimelinePage,
        meta: {
            requiresAuth: true,
            requiresDisclaimer: true,
            requiresPatient: true,
        },
    },
    {
        path: '/add-patient',
        name: 'page-new-patient',
        component: NewPatient,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/yield-patient',
        name: 'page-yield-patient',
        component: YieldPatientPage,
        meta: {
            requiresAuth: true,
            requiresPatient: true,
        },
    },
    {
        path: '/404',
        name: 'page-404',
        component: Page404,
    },
];

export default routes;
