const STORE_TOKEN = 'STORE_TOKEN';
const STORE_REFRESH_TOKEN = 'STORE_REFRESH_TOKEN';
const AUTHENTICATE = 'AUTHENTICATE';
const SET_AUTH_STATE = 'SET_AUTH_STATE';
const SET_PROFILE = 'SET_PROFILE';
const LOGOUT = 'LOGOUT';

const READ_TOKEN = 'READ_TOKEN';
const SET_DISCLAIMER_ACCEPTED = 'SET_DISCLAIMER_ACCEPTED';
const STORE_TOKEN_EXPIRATION = 'STORE_TOKEN_EXPIRATION';
const DECREMENT_PATIENT_REMAINING_QUESTIONS = 'DECREMENT_PATIENT_REMAINING_QUESTIONS';
const DECREMENT_PATIENT_REMAINING_MESSAGES = 'DECREMENT_PATIENT_REMAINING_MESSAGES';

const SET_NETWORK_STATE = 'SET_NETWORK_STATE';
const SET_TIMELINE_CONTENTS = 'SET_TIMELINE_CONTENTS';
const READ_TIMELINE_CONTENTS = 'READ_TIMELINE_CONTENTS';

const SET_BYPASS_DISCLAIMER = 'SET_BYPASS_DISCLAIMER';

const SET_SELECTED_PATIENT = 'SET_SELECTED_PATIENT';

export {
    READ_TOKEN,
    STORE_TOKEN,
    STORE_REFRESH_TOKEN,
    AUTHENTICATE,
    SET_AUTH_STATE,
    SET_PROFILE,
    LOGOUT,
    SET_DISCLAIMER_ACCEPTED,
    STORE_TOKEN_EXPIRATION,
    DECREMENT_PATIENT_REMAINING_QUESTIONS,
    DECREMENT_PATIENT_REMAINING_MESSAGES,
    SET_NETWORK_STATE,
    SET_TIMELINE_CONTENTS,
    READ_TIMELINE_CONTENTS,
    SET_BYPASS_DISCLAIMER,
    SET_SELECTED_PATIENT,
};
