const stringsEN = {
    askQuestion: {
        alert: 'We remind you that you can send {0}. The doctor will respond as soon as possible.',
        alertContentBold: 'only one question a day',
        maxReached: 'Daily questions max reached',
    },
    callLoading: 'Call loading...',
    cancel: 'Cancel',
    checkYourInbox: 'Check your inbox',
    close: 'Close',
    confirm: 'Confirm',
    next: 'Next',
    activate: 'Activate',
    ok: 'OK',
    confirmCardDelete: 'Confirm card delete',
    confirmCardDeleteSubtitle: 'The content you are hiding will no longer be visible. Do you wish to continue?',
    confirmCardDeleteTitle: 'Hide forever',
    contentVisibleUntil: 'content visible until',
    continue: 'Continue',
    developedBy: 'developed by',
    email: 'Email',
    emailAddress: 'Email address',
    emailInvalid: 'This field must be a valid email',
    enter: 'Enter',
    forgotPassword: 'Forgot password?',
    registerWizard: {
        requestorData: 'Requestor data',
        statements: 'Statements',
        confirm: 'Confirm',
        otpStep: {
            title: 'Verify mobile number',
            description: 'Within a few seconds you will receive a code via SMS to the mobile number you indicated: {phone}',
            insertCode: 'enter the code',
            invalidCode: 'Invalid code',
            validCode: 'Valid code',
            codeNotReceived: "Didn't receive the code?",
            sendAgain: 'Send it again',
            codeSent: 'Code sent',
            codeSentMessage: 'Within a few seconds you will receive a code via SMS to your mobile number: {phone}',
            errorSendingCode: 'Error sending code',
            waitOneMinuteToResend: 'Wait a minute for the verification code to be re-sent',
        },
        transferManagmentStep: {
            title: 'Management transfer',
            qrDescription: "Accept transfer by framing the QR code shown by the transferor's application",
            scanQr: 'Scan QR Code',
            codeRecognized: 'Recognized code',
            manualDescription: 'Or through the <b>transfer code</b> that the sender has communicated to you',
            qrCameraError: 'Error opening the camera. Check that you have granted permissions',
        },
        returnToMyData: 'Ritorna ai miei dati',
    },
    hi: 'Hi',
    isRequired: 'Field required',
    login: 'Log in',
    loginText:
        "Vicino&commat;TE è uno strumento digitale pensato da ASST Sette Laghi per rispondere all'esigenza dei familiari dei ricoverati di essere informati sulla loro evoluzione clinica ed anche di comunicare con i propri cari. {0}Vicino&commat;TE è un portale Web/App, di utilizzo facile ed intuitivo, che permette ai familiari di ricevere o richiedere informazioni sullo stato di salute del proprio caro ed anche di inviargli delle dediche o messaggi o immagini di sostegno e di affetto o effettuare videochiamate.",
    logout: 'Exit',
    maxChars: '(max. {chars} chars)',
    missingPermissionsWarning: 'To use the application correctly you must authorize the use of the microphone and the camera',
    networkOfflineMsg: 'Internet not available, please reconnect to see new content',
    nothingFound: 'There are no contents.',
    nothingHere: 'Nothing here',
    openDisclaimer: {
        descriptionFirst:
            'The images and videos you are about to view may have a strong emotional impact, as they concern a loved one who is not well.',
        descriptionSecond:
            'For this reason we also ask you to carefully consider whether to show them to children, to children and particularly impressionable people.',
        lastMessage: 'If you are not {nameSurname} close the application',
        okBtnLabel: 'I am {nameSurname} I understand and I want to continue',
        title: 'Warning',
    },
    password: 'Password',
    privacyPolicy: 'Privacy policy',
    proceed: 'Proceed',
    questionForDoctor: 'Question for the doctor',
    recoverPassword: {
        contacts: 'Or call {0} at the following times:',
        contactsTimes: 'mon-wed-fri: 8.00-12.00 and 12.30-14.00{0}tue-thur: 8.00-12.00 and 12.30-16.45',
        description: 'To recover your password, enter your username (e-mail) for accessing Vicino &commat;TE below',
        telNumber: '0461 904106',
        title: 'Did you forget your password?',
    },
    remainingChars: 'Remaining chars',
    remove: 'do not show this content anymore',
    requestActivation: 'Create an account',
    send: 'Send',
    sendDedicationToPatient: 'Degenza di { patient }.',
    sendDedicationToPatient2End: ' non appena sarà in grado di leggerli.',
    sendDedicationToPatient2Start: 'Questi messaggi saranno conservati e mostrati a ',
    sendDedicationToPatient3: 'Messaggio per { patient }',
    sendDedicationToPatientMaxChars: '(max. 2500 caratteri)',
    sendMessageToDoctor: 'Scrivi un messaggio al medico.',
    sendMessageToPatient: 'Lascia un messaggio a {name}.',
    sendMessageToPatient2: 'Li leggerà appena starà meglio',
    sendPhotoBtn: 'Allega',
    sendPhotoText: 'Allega una foto',
    updateApp: {
        message: 'An application update was downloaded. Click OK to restart the application.',
        ok: 'OK',
        title: 'Aggiorna app',
    },
    username: 'Username (e-mail)',
    usernameOrPasswordWrong: 'Username or password wrong.',
    whatIsVicino: "Cos'è Vicino&commat;TE",
    zupit: 'Zupit',
    notifications: {
        askPermission: {
            title: 'Enable notifications?',
            description: 'Turn on notifications to improve the reception of video calls',
            ok: 'OK',
        },
        notsupported: 'Your device/browser does not support notifications while the application is closed',
    },
};

export default stringsEN;
