import { Content, Identity, Patient } from '@/../client-generator/generated-clients/api-clients';
import { createStore, Store } from 'vuex';
import { parseJWT } from '../utilities/utilities';
import * as Action from './types/action-types';

export interface AppState {
    isAuthenticated: boolean;
    token: string | null;
    tokenExpiration: Date | null;
    refreshToken: string | null;
    refreshTokenExpiration: Date | null;
    profile: Identity | null;
    disclaimerAccepted: boolean;
    isNetworkOnline: boolean;
    timelineContents: Content[];
    bypassDisclaimer: false;
    selectedPatient: Patient | null;
}

const store = createStore({
    state: {
        isAuthenticated: false,
        token: null,
        tokenExpiration: null,
        refreshToken: null,
        refreshTokenExpiration: null,
        profile: null,
        disclaimerAccepted: false,
        isNetworkOnline: true,
        timelineContents: [],
        bypassDisclaimer: false,
        selectedPatient: null,
    },
    mutations: {
        [Action.STORE_TOKEN](state: AppState, token) {
            state.token = token;
            if (token) {
                window.localStorage.setItem(`_token`, token);
                const decodedToken = parseJWT(token);
                state.tokenExpiration = new Date(decodedToken.exp * 1000);
            } else {
                window.localStorage.removeItem(`_token`);
            }
        },
        [Action.STORE_REFRESH_TOKEN](state, refreshToken) {
            state.refreshToken = refreshToken;
            if (refreshToken) {
                window.localStorage.setItem(`_refreshToken`, refreshToken);
                const decodedToken = parseJWT(refreshToken);
                state.refreshTokenExpiration = new Date(decodedToken.exp * 1000);
            } else {
                window.localStorage.removeItem(`_refreshToken`);
            }
        },
        [Action.SET_AUTH_STATE](state, authState) {
            state.isAuthenticated = authState;
        },
        [Action.SET_PROFILE](state, profile) {
            state.profile = profile;
        },
        [Action.SET_DISCLAIMER_ACCEPTED](state, disclaimerAccepted) {
            state.disclaimerAccepted = disclaimerAccepted;
        },
        [Action.SET_BYPASS_DISCLAIMER](state, bypassDisclaimer) {
            state.bypassDisclaimer = bypassDisclaimer;
        },
        [Action.DECREMENT_PATIENT_REMAINING_QUESTIONS](state) {
            if (!state.selectedPatient) return;
            state.selectedPatient.remainingQuestions -= 1;
            if (state.selectedPatient.remainingQuestions < 0) state.selectedPatient.remainingQuestions = 0;

            if (state.profile) {
                const pat = state.profile.patients.find((k) => k.id === state.selectedPatient?.id);
                if (pat) pat.remainingQuestions = state.selectedPatient.remainingQuestions;
            }
        },
        [Action.DECREMENT_PATIENT_REMAINING_MESSAGES](state) {
            if (!state.selectedPatient) return;
            state.selectedPatient.remainingPatientMessages -= 1;
            if (state.selectedPatient.remainingPatientMessages < 0) state.selectedPatient.remainingPatientMessages = 0;

            if (state.profile) {
                const pat = state.profile.patients.find((k) => k.id === state.selectedPatient?.id);
                if (pat) pat.remainingPatientMessages = state.selectedPatient.remainingPatientMessages;
            }
        },
        [Action.SET_NETWORK_STATE](state, online) {
            state.isNetworkOnline = online;
        },
        [Action.SET_TIMELINE_CONTENTS](state, timelineContents) {
            state.timelineContents = timelineContents;
            localStorage.setItem('timelineContents', JSON.stringify(timelineContents));
        },
        [Action.READ_TIMELINE_CONTENTS](state) {
            const json = localStorage.getItem('timelineContents');
            if (!json) {
                state.timelineContents = [];
            } else {
                const tmpJson = JSON.parse(json as string);
                const tmpList = [];
                for (const item of tmpJson) {
                    tmpList.push(Content.fromJS(item));
                }
                state.timelineContents = tmpList;
            }
        },
        [Action.SET_SELECTED_PATIENT](state, patient) {
            state.selectedPatient = patient;
        },
    },
    actions: {
        [Action.READ_TOKEN]({ commit }) {
            const token = window.localStorage.getItem(`_token`);
            const refreshToken = window.localStorage.getItem(`_refreshToken`);
            if (token && refreshToken) {
                const decodedToken = parseJWT(token);
                const decodedRefreshToken = parseJWT(refreshToken);
                const nowDate = new Date().getTime() / 1000;
                if (decodedToken.exp < nowDate && decodedRefreshToken.exp < nowDate) {
                    this.dispatch(Action.LOGOUT);
                } else {
                    commit(Action.STORE_TOKEN, token);
                    commit(Action.STORE_REFRESH_TOKEN, refreshToken);
                }
            }
        },
        [Action.AUTHENTICATE]({ commit }, { token, refreshToken, authState }) {
            commit(Action.STORE_TOKEN, token);
            commit(Action.STORE_REFRESH_TOKEN, refreshToken);
            commit(Action.SET_AUTH_STATE, authState);
        },
        [Action.STORE_TOKEN]({ commit }, { token }) {
            commit(Action.STORE_TOKEN, token);
        },
        [Action.LOGOUT]({ commit }) {
            commit(Action.STORE_TOKEN, null);
            commit(Action.STORE_REFRESH_TOKEN, null);
            commit(Action.SET_AUTH_STATE, false);
            commit(Action.SET_PROFILE, null);
            commit(Action.SET_TIMELINE_CONTENTS, []);
        },
        [Action.SET_PROFILE]({ commit }, profile) {
            commit(Action.SET_PROFILE, profile);
            commit(Action.SET_AUTH_STATE, true);
        },
        [Action.SET_DISCLAIMER_ACCEPTED]({ commit }, profile) {
            commit(Action.SET_DISCLAIMER_ACCEPTED, profile);
        },
        [Action.SET_BYPASS_DISCLAIMER]({ commit }, bypassDisclaimer) {
            commit(Action.SET_BYPASS_DISCLAIMER, bypassDisclaimer);
        },
        [Action.DECREMENT_PATIENT_REMAINING_QUESTIONS]({ commit }) {
            commit(Action.DECREMENT_PATIENT_REMAINING_QUESTIONS);
        },
        [Action.DECREMENT_PATIENT_REMAINING_MESSAGES]({ commit }) {
            commit(Action.DECREMENT_PATIENT_REMAINING_MESSAGES);
        },
        [Action.SET_NETWORK_STATE]({ commit }, online) {
            commit(Action.SET_NETWORK_STATE, online);
        },
        [Action.SET_TIMELINE_CONTENTS]({ commit }, timelineContents) {
            commit(Action.SET_TIMELINE_CONTENTS, timelineContents);
        },
        [Action.READ_TIMELINE_CONTENTS]({ commit }) {
            commit(Action.READ_TIMELINE_CONTENTS);
        },
        [Action.SET_SELECTED_PATIENT]({ commit }, patient) {
            commit(Action.SET_SELECTED_PATIENT, patient);
        },
    },
    getters: {
        isAuthenticated: (state) => state.isAuthenticated,
        isNetworkOnline: (state) => state.isNetworkOnline,
        activePatients: (state) => state.profile?.patients ?? [],
        pendingPatients: (state) => state.profile?.pendingPatients ?? [],
    },
});

export default store as Store<AppState>;
