import { App } from 'vue';
import { Field, Form, ErrorMessage, configure } from 'vee-validate';
import { localize } from '@vee-validate/i18n';
import it from '@vee-validate/i18n/dist/locale/it.json';
import { defineRule } from 'vee-validate';
import * as AllRules from '@vee-validate/rules';
import { setLocale } from '@vee-validate/i18n';
import i18n from '../translations/i18n';

const configVeeValidate = (app: App<Element>) => {
    app.component('VField', Field);
    app.component('VForm', Form);
    app.component('VErrorMessage', ErrorMessage);
    Object.keys(AllRules).forEach((rule: any) => {
        defineRule(rule, (AllRules as any)[rule]);
    });

    defineRule('password', (value) => {
        if (!value || !value.length) {
            return true;
        }

        // Check if valid password
        if (/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/.test(value)) {
            return true;
        }

        return i18n.global.t('isNotValidPassword');
    });

    defineRule('specialChar', (value) => {
        if (!value || !value.length) {
            return true;
        }

        // Check if contains special char
        if (/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(value)) {
            return true;
        }

        return i18n.global.t('isNotValidPassword');
    });

    defineRule('fiscalCode', (value) => {
        if (!value || !value.length) {
            return true;
        }

        // Check if valid fiscal code
        if (
            /^(?:[A-Z][AEIOU][AEIOUX]|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/.test(
                value
            )
        ) {
            return true;
        }

        return i18n.global.t('isNotValidFiscalCode');
    });

    defineRule('phoneNumber', (value) => {
        if (!value || !value.length) {
            return true;
        }

        // Check if valid phone number
        if (/^^(([+]|00)39)?((3[1-6][0-9]))(\d{7})$/.test(value)) {
            return true;
        }

        return i18n.global.t('isNotValidPhoneNumber');
    });

    configure({
        generateMessage: localize({
            it,
        }),
    });
    setLocale('it');
};

export default configVeeValidate;
