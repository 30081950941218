const stringsIT = {
    activate: 'Attiva',
    apiError: "C'è stato un problema nell'applicazione, si prega di riprovare più tardi",
    askQuestion: {
        alert: 'Ti ricordiamo che potrai inviare {0}. Il medico risponderà non appena gli sarà possibile.',
        alertContentBold: 'una sola domanda al giorno',
        maxReached: 'Numero massimo giornaliero di domande raggiunto',
    },
    back: 'Indietro',
    backendValidationError: "C'è un errore nei dati inseriti, ricontrolla i dati inseriti",
    callLoading: 'Attendi... videochiamata in arrivo',
    cancel: 'Annulla',
    cantSendMessageToDoctor1: 'Oggi hai già inviato un messaggio',
    cantSendMessageToDoctor2: 'Puoi inviarne',
    cantSendMessageToDoctor3: 'uno al giorno',
    cantSendMessageToPatient: 'Puoi inviare al massimo 5 messaggi al giorno a {name}.',
    checkYourInbox: 'Controlla la tua casella e-mail',
    close: 'Chiudi',
    confirm: 'Conferma',
    confirmCardDelete: 'Conferma eliminare la card',
    confirmCardDeleteSubtitle: 'Il contenuto che stai nascondendo non sarà più visibile. Vuoi continuare?',
    confirmCardDeleteTitle: 'Nascondi per sempre',
    contentVisibleUntil: 'contenuto visible fino al',
    continue: 'Continua',
    deletePhoto: 'Elimina',
    developedBy: 'sviluppato da',
    email: 'Email',
    emailAddress: 'Indirizzo email',
    emailInvalid: 'Questo campo deve essere un indirizzo email valido',
    enter: 'Entra',
    fiscalCodeNoMatchWithName: 'Il codice fiscale inserito non corrisponde con il nome e/o cognome inserito',
    forgotPassword: 'Password dimenticata?',
    generalError: "Errore nell'applicazione!",
    help: 'Aiuto',
    hi: 'Ciao',
    invalidFiscalCode: 'Il codice fiscale inserito non è valido',
    invalidCode: 'Codice non valido',
    invalidCodeText: 'Il codice che hai inserito non è valido. Riprova con un codice valido!',
    isNotValidFiscalCode: 'Il codice fiscale inserito non è valido',
    isNotValidPassword: 'La password non è valida',
    isNotValidPhoneNumber: 'Devi inserire un numero di telefono valido',
    isRequired: 'Campo richiesto',
    jitsiErrors: 'Ci sono stati errori nel recuperare i dati necessari ad avviare la chiamata. Si prega di riprovare più tardi.',
    login: 'Accedi',
    loginText:
        "{2} è uno strumento digitale pensato da ASST Sette Laghi per rispondere all'esigenza dei familiari dei ricoverati di essere informati sulla loro evoluzione clinica ed anche di comunicare con i propri cari. {0}{1}{2} è un portale Web/App, di utilizzo facile ed intuitivo, che permette ai familiari di ricevere o richiedere informazioni sullo stato di salute del proprio caro ed anche di inviargli delle dediche o messaggi o immagini di sostegno e di affetto o effettuare videochiamate.",
    logout: 'Esci',
    maxChars: '(max. {chars} caratteri)',
    missingPermissionsWarning: "Per utilizzare correttamente l'applicazione devi autorizzare l'uso del microfono e della videocamera",
    networkOfflineMsg: 'Internet non disponibile, ricollegarsi per vedere i nuovi contenuti',
    newPatient: {
        backToHome: 'Ritorna alla home',
        cancelRequest: 'Annulla richiesta',
        requestNewLink: 'Richiesta nuovo collegamento',
        successDesc:
            "La tua richiesta di attivazione nuovo collegamento è stata inoltrata correttamente. Riceverai una comunicazione di conferma all'indirizzo email che hai indicato durante la registrazione",
    },
    next: 'Avanti',
    nothingFound: 'Non ci sono contenuti.',
    nothingHere: 'Niente qui',
    notifications: {
        askPermission: {
            description: 'Attiva le notifiche per migliorare la ricezione delle videochiamate',
            ok: 'OK',
            title: 'Attivare notifiche?',
        },
        notsupported: "Il tuo dispositivo/browser non supporta le notifiche mentre l'applicazione è chiusa",
    },
    notRegistered: 'Non sei registrato',
    ok: 'OK',
    openDisclaimer: {
        descriptionFirst:
            'Le immagini e i video che stai per visionare potrebbero essere di forte impatto emotivo, poiché riguardano un tuo caro che non sta bene.',
        descriptionSecond:
            'Per questo motivo ti chiediamo anche di valutare con attenzione se mostrarle a bambini, a ragazzi e alle persone particolarmente impressionabili.',
        lastMessage: 'Se non sei {nameSurname} chiudi l’applicazione',
        okBtnLabel: 'Sono {nameSurname} ho compreso e voglio proseguire',
        title: 'Attenzione!',
    },
    password: 'Password',
    privacyPolicy: 'Informativa privacy',
    proceed: 'Procedi',
    questionForDoctor: 'Domanda per il medico',
    recoverPassword: {
        contacts: 'Oppure scrivi al seguente indirizzo: {0}',
        contactsTimes: 'lun-mer-ven: 8.00-12.00 e 12.30-14.00{0}mar-gio: 8.00-12.00 e 12.30-16.45',
        description: 'Per recuperare la tua password inserisci qui sotto il tuo nome utente (e-mail) di accesso a Vicino&commat;TE',
        email: 'vicinoate&commat;asst-settelaghi.it',
        telNumber: '0461 904106',
        title: 'Hai dimenticato la Password?',
    },
    registerWizard: {
        accessService: 'Accedi al servizio',
        activationMessage:
            "<strong>l'attivazione del servizio “Vicino&commat;Te”</strong> che consente ai parenti di pazienti, ricoverati in reparti nei quali non è consentito agli stessi di recarsi personalmente (quali ad esempio Rianimazione, Terapia Intensiva e Malattie Infettive della APSS), di ricevere informazioni del proprio congiunto ed eventualmente di inviargli dei messaggi, ed a tal fine, avvalendosi delle disposizioni di cui all'art.47 del D.P.R. 28.12.2000, n. 445 e consapevole delle sanzioni penali, nel caso di dichiarazioni non veritiere, di formazione o uso di atti falsi, richiamate dall'art.76 del D.P.R. 445/2000, sotto la propria personale responsabilità,",
        alsoDeclare: 'Dichiaro inoltre',
        ask: 'chiede',
        attach: 'Allego',
        back: 'Retro',
        bornAt: 'nato a',
        confirm: 'Conferma',
        confirmClosestRelative:
            'di essere <strong>il congiunto più prossimo al suddetto paziente</strong> ovvero di essere stato designato dagli altri congiunti quale referente per tale servizio;',
        confirmCriminalPenalties:
            "di essere <strong>consapevole delle sanzioni penali</strong> richiamate dall'art. 76 del D.P.R. 445/2000, nel caso di dichiarazioni non veritiere, di formazione o uso di atti falsi eseguite sotto la propria personale responsabilità;",
        confirmEmail: 'Conferma email',
        confirmEmailText:
            "che l'indirizzo e-mail a cui trasmettere le informazioni sull'evoluzione dello stato di salute del predetto paziente è ",
        confirmPassword: 'Conferma password',
        confirmPersonalData:
            "di essere informato/a, ai sensi dell'art. 13 del Regolamento UE 2016/679 in materia di protezione dei dati personali e dell'art. 14, D.L. 9.3.2020, n. 14 recante disposizioni particolari nell'ambito dell'emergenza Covid-19, che i <strong>dati personali</strong> raccolti tramite la presente dichiarazione saranno trattati, anche con strumenti informatici, esclusivamente nell'ambito e per le finalità per la fruizione del servizio in oggetto, e che saranno conservati in Vicino&commat;Te per <strong>10 anni dal termine del ricovero dal paziente</strong> (vedere anche ",
        confirmPrivacy:
            "di essere consapevole che ai sensi della normativa sulla privacy <strong>è vietata la diffusione dei dati relativi alla salute del/la paziente</strong>, si impegna a non diffondere le immagini, i messaggi e i documenti presenti in Vicino&commat;Te ed a mantenere riservato il codice personale di accesso fornito per l'accesso alle stesse. L'Azienda declina ogni responsabilità in caso di violazioni.",
        credentials: 'Credenziali di accesso',
        credentialsDesc: 'Indica un indirizzo email sul quale attivare il servizio ed una password personale per accedere',
        declareToBe: 'Dichiaro di essere',
        documentType: 'Tipo di documento',
        documentTypeEnum: {
            drivers: 'Patente',
            idcard: "Carta d'identità",
            passport: 'Passaporto',
            staypermit: 'Permesso di soggiorno',
        },
        email: 'Indirizzo email',
        emergencyRoom: 'in un <strong>PRONTO SOCCORSO</strong>',
        firstName: 'Nome',
        fiscalCode: 'Codice fiscale',
        front: 'Fronte',
        hospitalUnit: 'in un <strong>REPARTO</strong> ospedaliero',
        identityCard: "Documento d'identità",
        identityCardDesc:
            "Allegare immagine di un documento di identità del dichiarante in corso di validità.\n Se disponi di un'unica immagine Fronte/retro caricala due volte",
        lastName: 'Cognome',
        myself: 'Il sottoscritto',
        needInfo: 'Hai bisogno di informazioni?',
        newActivation: 'Voglio richiedere una <strong>NUOVA ATTIVAZIONE</strong> per un mio caro',
        next: 'Avanti',
        otpStep: {
            codeNotReceived: 'Non hai ricevuto il codice?',
            codeSent: 'Codice inviato',
            codeSentMessage: 'Entro alcuni secondi riceverai un codice via SMS al numero di cellulare: {phone}',
            description: 'Entro alcuni secondi riceverai un codice via SMS al numero di cellulare da te indicato: {phone}',
            errorSendingCode: 'Errore invio codice',
            insertCode: 'Inserisci il codice',
            invalidCode: 'Codice non valido',
            sendAgain: 'Invialo nuovamente',
            title: 'Verifica numero di cellulare',
            validCode: 'Codice valido',
            waitOneMinuteToResend: 'Attendere un minuto per il re-invio del codice di verifica',
        },
        overviewConfirm: 'Confermo di voler <strong>attivare la registrazione</strong>',
        overviewConfirmDataIsCorrect:
            "confermo <strong>la correttezza dei dati riportati nella presente pagina di istanza/dichiarazione per l'attività del servizio vicino&commat;TE</strong>",
        overviewConfirmPrivacy: 'Confermo di accettare la',
        overviewDeclareToBe: 'Dichiara di essere',
        overviewPhone: 'recapito telefonico',
        overviewRelation: 'del paziente',
        overviewTitle: 'Riepilogo richiesta',
        overviewUnit: 'reparto e/o ospedale del ricovero',
        password: 'Password',
        passwordHelp:
            'La password deve contenere almeno 8 caratteri, 1 lettera minuscola, 1 maiuscola, 1 cifra, un carattere speciale (!,$,#, …)',
        patientData: 'Dati del paziente',
        patientFirstName: 'Nome del paziente',
        patientFiscalCode: 'Codice fiscale del paziente',
        patientLastName: 'Cognome del paziente',
        patientPlace: 'Sai dove si trova il tuo familiare?',
        phone: 'Numero di cellulare',
        privacyPolicy: 'informativa del servizio',
        register: 'Registrati',
        relationGrade: 'Grado di parentela',
        relationType: {
            cohabitant: 'Convivente',
            other: 'Altri',
            parent: 'Genitore',
            sibling: 'Fratello/Sorella',
            son: 'Figlio/a',
            spouse: 'Coniuge',
            tutor: 'Tutor',
        },
        requestorData: 'Dati richiedente',
        requestSuccess: 'Richiesta inviata con successo',
        requestSuccessText:
            "La tua richiesta di attivazione del servizio vicino&commat;TE è stata inoltrata correttamente. Riceverai una comunicazione di conferma di attivazione all'indirizzo email che hai indicato durante la registrazione",
        returnToMyData: 'Ritorna ai miei dati',
        statements: 'Dichiarazioni',
        transferManagmentStep: {
            codeRecognized: 'Codice riconosciuto',
            manualDescription: 'Oppure attraverso il <b>codice di trasferimento</b> che il trasferente ti ha comunicato',
            qrCameraError: "Errore nell'aprire la fotocamera. Controlla di aver concesso i permessi",
            qrDescription: 'Accetta trasferimento inquadrando il QR code mostrato dall’applicazione del trasferente',
            scanQr: 'Inquadra QR Code',
            title: 'Trasferimento gestione',
        },
        transferPatient:
            "Un'altra persona mi vuole trasferire la gestione di un paziente tramite <strong>codice</strong> o mostrandomi <strong>QR code</strong> di trasferimento",
        uploadBack: 'Carica retro',
        uploadFront: 'Carica fronte',
        uploadPhoto: 'Carica foto',
        uploadYourPhoto: 'Carica una tua foto',
        uploadYourPhotoDesc: 'Fatti un Selfie o carica una tua foto: ci permetterà di associarti più velocemente',
        writeUs: 'Scrivici',
    },
    relativesListPage: {
        actionBtn: 'Attiva nuovo paziente',
        actionDescription:
            'Vuoi richiedere l’attivazione di un <strong>nuovo paziente</strong> o hai ricevuto un <strong>codice di trasferimento</string>?',
        emergencyRoom: 'PRONTO SOCCORSO',
        title: 'Sei associato ai seguenti pazienti:',
        unit: 'REPARTO',
        waitingActivation: 'in attesa di attivazione',
    },
    remainingChars: 'Caratteri rimanenti',
    remove: 'non mostrare più questo contenuto',
    requestActivation: 'Crea un account',
    requiredFieldsWarn: 'Inserire tutti i campi obbligatori',
    select: 'Seleziona',
    send: 'Invia',
    sendDedicationToPatient: 'Degenza di { patient }.',
    sendDedicationToPatient2End: ' non appena sarà in grado di leggerli.',
    sendDedicationToPatient2Start: 'Questi messaggi saranno conservati e mostrati a ',
    sendDedicationToPatient3: 'Messaggio per { patient }',
    sendDedicationToPatientMaxChars: '(max. 2500 caratteri)',
    sendMessageToDoctor: 'Scrivi un messaggio al medico.',
    sendMessageToPatient: 'Lascia un messaggio a {name}.',
    sendMessageToPatient2: 'Li leggerà appena starà meglio',
    sendPhotoBtn: 'Allega',
    sendPhotoText: 'Allega una foto',
    sidebar: {
        activateNewPatient: 'Attiva nuovo paziente',
        appDescription: "Vicino&commat;Te è un'applicazione lorem ipsum dolor sit amet testo breve di spiegazione e di referenze",
        home: 'Home',
        logout: "Esci dall'applicazione",
        needHelp: 'Hai bisogno di aiuto?',
        privacyPolicy: 'Privacy Policy',
        termsAndConditions: 'Terms and conditions',
        transferCode: 'Hai ricevuto un codice di trasferimento?',
    },
    tryLate: 'Si prega di riprovare più tardi',
    updateApp: {
        message: "È stato scaricato un aggiornamento dell'applicazione. Clicca OK per riavviare l'applicazione.",
        ok: 'OK',
        title: 'Aggiorna app',
    },
    username: 'Nome utente (e-mail)',
    usernameOrPasswordWrong: 'Nome utente o password errati.',
    view: 'Visualizza',
    viewPhoto: 'Visualizza',
    warning: 'Attenzione',
    whatIsVicino: "Cos'è Vicino&commat;TE",
    yieldPatient: {
        alertMessage: 'Ho letto e compreso come funziona il trasferimento di gestione del mio caro e voglio visualizzare il codice',
        backToHome: 'Torna alla home',
        confirmTransfer: 'Conferma trasferimento',
        emergencyRoom: 'PRONTO SOCCORSO',
        haveToAccept: 'Devi confermare di aver compreso per visualizzare il codice di trasferimento',
        qrCodeDesc: 'Mostra il QR code alla persona alla quale vuoi trasferire la gestione del paziente',
        showCode: 'Visualizza codice',
        transferDesc:
            'Puoi trasferire la gestione del tuo caro ad un’altra persona semplicemente mostrando il <strong>codice di trasferimento o il QR code corrispondente</strong>. Non appena l’altra persona attiverà il codice non avrai più accesso alle informazioni relative alla degenza.',
        transferManage: 'Trasferimento gestione',
        unit: 'REPARTO',
    },
    zupit: 'Zupit',
};
export default stringsIT;
