import { createApp } from 'vue';
import App from './App.vue';
import router from './core/router';
import i18n, { configI18n } from './core/translations/i18n';
import config from './core/config/config';

// PWA service worker
import './registerServiceWorker.ts';

import { IonicVue } from '@ionic/vue';
import { defineCustomElements } from '@ionic/pwa-elements/loader';

import configIonic from './core/config/config-ionic';
import configVeeValidate from './core/config/config-veevalidate';
import store from './core/store/store';
import { READ_TOKEN } from './core/store/types/action-types';
import TheHeader from '@/views/components/TheHeader/TheHeader.vue';
import TheSidebar from '@/views/components/TheSidebar/TheSidebar.vue';
import firebase from 'firebase';
store.dispatch(READ_TOKEN);

const app = createApp(App).use(IonicVue, config).use(router).use(i18n).use(store);

configIonic(app);
configVeeValidate(app);
configI18n(app);
app.component('TheHeader', TheHeader);
app.component('TheSidebar', TheSidebar);

router.isReady().then(() => {
    app.mount('#app');

    firebase.initializeApp({
        apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
        authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
        projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.VUE_APP_FIREBASE_APP_ID,
        measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
    });

    defineCustomElements(window);
});
