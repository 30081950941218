<template>
    <ion-header>
        <ion-toolbar class="navbar">
            <router-link to="/" class="d-block">
                <Logo class="navbar__logo" slot="start" />
            </router-link>
            <div slot="end" class="d-flex align-items-center">
                <div class="navbar__logo-region">
                    <img
                        src="~@/assets/images/logo-asst.jpg"
                        alt="logo-asst"
                        slot="end"
                    />
                </div>
                <ion-menu-button
                    v-if="isAuthenticated"
                    class="text-black"
                ></ion-menu-button>
            </div>
        </ion-toolbar>
        <div
            class="alert alert-danger navbar__offline-alert pb-2"
            role="alert"
            v-if="!isNetworkOnline"
        >
            {{ $t("networkOfflineMsg") }}
        </div>
    </ion-header>
</template>

<script lang="ts" src="./TheHeader.ts"></script>

<style lang="scss" src="./TheHeader.scss"></style>
