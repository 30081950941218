import { Options, Vue } from 'vue-class-component';
import IconTimes from '@/views/components/icons/IconTimes.vue';
import { menuController } from '@ionic/vue';
import authClient from '@/core/api/auth-service';
import { LOGOUT } from '@/core/store/types/action-types';
import { mapState } from 'vuex';
import { Identity } from 'client-generator/generated-clients/api-clients';
import { logOutOutline as iconLogOutOut } from 'ionicons/icons';
import IconZupit from '@/views/components/icons/IconZupit.vue';
import config from '@/core/config/config';

@Options({
    name: 'TheSidebar',
    components: { IconTimes, IconZupit },
    computed: {
        ...mapState({ profile: 'profile' }),
    },
})
export default class TheSidebar extends Vue {
    profile!: Identity;

    iconLogOutOut = iconLogOutOut;
    privacyPolicyUrl = process.env.VUE_APP_PRIVACY_POLICY_URL;
    helpUrl = config.helpUrl;

    closeMenu() {
        menuController.close();
    }

    goTo(route: string, param: string | null = null) {
        this.$router.replace({
            name: route,
            query: param ? { type: param } : undefined,
        });
        this.closeMenu();
    }

    async logout() {
        try {
            this.closeMenu();
            await authClient.logout();
        } finally {
            this.$store.dispatch(LOGOUT);
        }
    }
}
