import { createRouter, createWebHistory } from '@ionic/vue-router';
import meClient from '../api/me-service';
import store from '../store/store';
import { LOGOUT, SET_PROFILE } from '../store/types/action-types';
import routes from './routes';

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach(async (to, from, next) => {
    if (!to.matched.length) {
        next('/404');
        return;
    }

    next();
});

router.beforeEach(async (to, from, next) => {
    if (!to.matched.length) {
        next('/404');
        return;
    }

    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
    const requiresDisclaimer = to.matched.some((record) => record.meta.requiresDisclaimer);
    const requiresPatient = to.matched.some((record) => record.meta.requiresPatient);

    if (requiresAuth && !store.state.profile) {
        try {
            const profile = await meClient.me();
            store.dispatch(SET_PROFILE, profile);
        } catch (error) {
            if (error.status === 401) {
                store.dispatch(LOGOUT);
            }
        }
    }
    const { isAuthenticated } = store.getters;
    const { disclaimerAccepted } = store.state;

    if (requiresAuth && isAuthenticated && requiresDisclaimer && !disclaimerAccepted && to.name !== 'page-disclaimer') {
        next('/disclaimer');
        return;
    }

    const { selectedPatient } = store.state;

    if (requiresPatient && !selectedPatient) {
        next('/relatives-list');
    }

    if (requiresAuth && !isAuthenticated) {
        next('/login');
    } else {
        next();
    }
});

export default router;
