<template>
    <ion-app>
        <TheHeader />
        <TheSidebar />
        <ion-router-outlet id="main" />
    </ion-app>
</template>

<script lang="ts" src="./App.ts"></script>

<style lang="scss">
@import "@/assets/scss/styles.scss";

.help-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 8px;
    padding-right: 8px;
}

.top-help-button {
    display: inline-flex;
    align-items: center;
    padding: 8px;
    padding-left: 0;

    color: var(--color-gray);

    font-size: 13px;
    line-height: 1;
    text-decoration: none;
}
</style>
