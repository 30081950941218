import axios from 'axios';
import { TokenRefresh } from '@/../client-generator/generated-clients/api-clients';
import authClient from '../api/auth-service';
import store from '../store/store';
import { LOGOUT, STORE_TOKEN } from '../store/types/action-types';

const axiosInstance = axios.create();

axiosInstance.defaults.timeout = 30000;

axiosInstance.interceptors.request.use(async (config) => {
    if (store.state.tokenExpiration !== null && store.state.tokenExpiration < new Date()) {
        try {
            const newToken = await authClient.refresh(new TokenRefresh({ refresh: store.state.refreshToken as string }));
            store.dispatch(STORE_TOKEN, { token: newToken.access });
        } catch (e) {
            store.dispatch(LOGOUT);
        }
    }
    if (store.state.token) config.headers.Authorization = `Bearer ${store.state.token}`;
    else config.headers.Authorization = '';
    return config;
});

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = error.config;
        const usedBearer = !!originalRequest.headers.Authorization;
        if (error.response.status === 401 && !originalRequest._retry && usedBearer) {
            originalRequest._retry = true;
            try {
                const newToken = await authClient.refresh(new TokenRefresh({ refresh: store.state.refreshToken as string }));
                store.dispatch(STORE_TOKEN, { token: newToken.access });
                return axiosInstance(originalRequest);
            } catch (e) {
                store.dispatch(LOGOUT);
            }
        }

        return Promise.reject(error);
    }
);

export default axiosInstance;
