import { isPlatform } from '@ionic/vue';

interface AppConfig {
    baseApiUrl: string;
    isAndroid: boolean;
    isIphone: boolean;
    helpUrl: string;
}

const config: AppConfig = {
    baseApiUrl: process.env.VUE_APP_BASE_API_URL as string,
    isAndroid: isPlatform('android'),
    isIphone: isPlatform('iphone'),
    helpUrl: process.env.VUE_APP_HELP_URL || '#',
};

export default config;
