import { App } from 'vue';
import {
    IonPage,
    IonContent,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonItem,
    IonInput,
    IonLabel,
    IonButton,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonTextarea,
    IonButtons,
    IonSelect,
    IonSelectOption,
    IonMenuButton,
    IonMenu,
    IonList,
    IonIcon,
    IonRadioGroup,
    IonRadio,
    IonCheckbox,
} from '@ionic/vue';
import { RouterLink } from 'vue-router';

const configIonic = (app: App<Element>) => {
    app.component('ion-content', IonContent);
    app.component('ion-page', IonPage);

    app.component('ion-card', IonCard);
    app.component('ion-card-header', IonCardHeader);
    app.component('ion-card-title', IonCardTitle);
    app.component('ion-card-content', IonCardContent);

    app.component('ion-item', IonItem);

    app.component('ion-label', IonLabel);
    app.component('ion-input', IonInput);
    app.component('ion-select', IonSelect);
    app.component('ion-select-option', IonSelectOption);

    app.component('ion-button', IonButton);
    app.component('ion-checkbox', IonCheckbox);
    app.component('router-link ', RouterLink);

    app.component('ion-radio-group', IonRadioGroup);
    app.component('ion-radio', IonRadio);
    app.component('ion-header', IonHeader);
    app.component('ion-menu-button', IonMenuButton);
    app.component('ion-menu', IonMenu);
    app.component('ion-list', IonList);
    app.component('ion-icon', IonIcon);
    app.component('ion-toolbar', IonToolbar);
    app.component('ion-title', IonTitle);
    app.component('ion-textarea', IonTextarea);
    app.component('ion-buttons', IonButtons);
};

export default configIonic;
