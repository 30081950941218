import { toastController } from '@ionic/vue';

const parseJWT = (token: string) => {
    try {
        return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
        return null;
    }
};

const message = async (message: string) => {
    const toast = await toastController.create({
        message: message,
        duration: 2000,
        position: 'bottom',
    });
    return toast.present();
};

export { parseJWT, message };
