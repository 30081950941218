import axios from 'axios';
import store from '../store/store';

const axiosInstance = axios.create();

axiosInstance.defaults.timeout = 30000;

axiosInstance.interceptors.request.use((config) => {
    if (store.state.token) config.headers.Authorization = `Bearer ${store.state.token}`;
    else config.headers.Authorization = '';
    return config;
});

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        return Promise.reject(error);
    }
);

export default axiosInstance;
