import { Options, Vue } from 'vue-class-component';
import { IonHeader, IonToolbar, IonTitle } from '@ionic/vue';
import { mapGetters } from 'vuex';
import Logo from '@/views/components/Logo.vue';

@Options({
    name: 'TheHeader',
    components: {
        IonHeader,
        IonToolbar,
        IonTitle,
        Logo,
    },
    computed: {
        ...mapGetters(['isNetworkOnline', 'isAuthenticated']),
    },
})
export default class TheHeader extends Vue {
    isAuthenticated!: boolean;
}
