<template>
    <ion-menu
        side="end"
        content-id="main"
        menu-id="custom"
        type="overlay"
        class="sidebar"
    >
        <ion-content>
            <div class="d-flex align-items-end">
                <div class="ml-auto">
                    <ion-button
                        mode="ios"
                        class="button-close"
                        fill="clear"
                        @click="closeMenu"
                    >
                        <IconTimes class="text-black" />
                    </ion-button>
                </div>
            </div>
            <ion-item class="mt-5">
                <ion-button
                    mode="ios"
                    fill="clear"
                    class="custom-link"
                    @click="goTo('page-relatives-list')"
                >
                    {{ $t("sidebar.home") }}
                </ion-button>
            </ion-item>
            <ion-item>
                <ion-button
                    mode="ios"
                    fill="clear"
                    class="custom-link"
                    @click="goTo('page-new-patient', 'new')"
                >
                    {{ $t("sidebar.activateNewPatient") }}
                </ion-button>
            </ion-item>
            <ion-item class="mt-1">
                <ion-button
                    mode="ios"
                    fill="clear"
                    class="custom-link"
                    @click="goTo('page-new-patient', 'transfer')"
                >
                    {{ $t("sidebar.transferCode") }}
                </ion-button>
            </ion-item>
            <ion-item class="mt-3">
                <a class="custom-link" target="_system" :href="helpUrl">
                    {{ $t("sidebar.needHelp") }}
                </a>
            </ion-item>
            <ion-item class="mt-2">
                <div class="sidebar__divider"></div>
            </ion-item>
            <ion-item v-if="profile">
                <div class="d-block">
                    <div class="sidebar__username">
                        {{ profile.firstName }} {{ profile.lastName }}
                    </div>
                    <div class="sidebar__email">{{ profile.email }}</div>
                </div>
            </ion-item>
            <ion-item class="mt-2">
                <ion-button
                    fill="clear"
                    class="text-black sidebar__logout"
                    @click="logout"
                >
                    <ion-icon slot="start" :icon="iconLogOutOut"></ion-icon>
                    {{ $t("sidebar.logout") }}
                </ion-button>
            </ion-item>

            <ion-item class="mt-5">
                <div>
                    <div class="sidebar__desc">
                        {{ $t("sidebar.appDescription") }}
                    </div>
                    <div class="ml-auto mt-2">
                        <a
                            href="https://bit.ly/2WKwAuK"
                            class="sidebar__credits"
                            target="_system"
                        >
                            <em>{{ $t("developedBy") }}</em>
                            <strong class="ml-1">{{ $t("zupit") }}</strong>
                            <IconZupit
                                class="sidebar__credits-zupit-icon ml-1"
                            />
                        </a>
                    </div>
                </div>
            </ion-item>
            <ion-item class="mt-3">
                <div class="d-flex">
                    <a
                        :href="privacyPolicyUrl"
                        class="sidebar__url"
                        target="_system"
                        >{{ $t("sidebar.privacyPolicy") }}</a
                    >

                    <a
                        :href="privacyPolicyUrl"
                        class="sidebar__url ml-3"
                        target="_system"
                        >{{ $t("sidebar.termsAndConditions") }}</a
                    >
                </div>
            </ion-item>
        </ion-content>
    </ion-menu>
</template>

<script lang="ts" src="./TheSidebar.ts"></script>
<style lang="scss" src="./TheSidebar.scss"></style>
