import { PluginListenerHandle, Plugins } from '@capacitor/core';
import { alertController, IonApp, IonRouterOutlet } from '@ionic/vue';
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';
import { SET_DISCLAIMER_ACCEPTED, SET_NETWORK_STATE } from './core/store/types/action-types';

@Options({
    components: {
        IonApp,
        IonRouterOutlet,
    },
    computed: {
        ...mapState(['isAuthenticated']),
        ...mapState(['bypassDisclaimer']),
    },
    watch: {
        isAuthenticated(value, oldValue) {
            if (oldValue && !value) {
                this.executeLogout();
            }
        },
    },
})
export default class App extends Vue {
    swRegistration: ServiceWorkerRegistration | null = null;
    refreshing = false;
    networkListener: PluginListenerHandle | null = null;
    bypassDisclaimer!: boolean;

    async created() {
        document.addEventListener('swUpdated', this.updateAvailableEvent, { once: true });

        try {
            // Prevent multiple refreshes
            navigator.serviceWorker.addEventListener('controllerchange', () => {
                if (this.refreshing) return;
                this.refreshing = true;
                window.location.reload();
            });

            const { Network } = Plugins;
            this.networkListener = Network.addListener('networkStatusChange', (status) => {
                this.$store.dispatch(SET_NETWORK_STATE, status.connected);
            });
            try {
                this.$store.dispatch(SET_NETWORK_STATE, (await Network.getStatus()).connected);
            } catch (e) {
                console.error('No network access permission', e);
            }
        } catch (error) {
            console.log(error.toString());
        }
    }

    mounted() {
        document.addEventListener('visibilitychange', this.visibilityChanged, false);
    }

    unmounted() {
        document.removeEventListener('visibilitychange', this.visibilityChanged);
        this.networkListener?.remove();
    }

    visibilityChanged() {
        if (document.hidden) {
            const requiresAuth = this.$route.matched.some((record) => record.meta.requiresAuth);
            const requiresDisclaimer = this.$route.matched.some((record) => record.meta.requiresDisclaimer);
            if (requiresAuth && requiresDisclaimer && !this.bypassDisclaimer) {
                this.$store.dispatch(SET_DISCLAIMER_ACCEPTED, false);
                this.$router.replace({ name: 'page-relatives-list' });
            }
        }
    }

    async updateAvailableEvent(event: any) {
        this.swRegistration = event.detail;
        const alert = await alertController.create({
            header: this.$t('updateApp.title'),
            message: this.$t('updateApp.message'),
            buttons: [
                {
                    text: this.$t('cancel'),
                    handler: () => {
                        alertController.dismiss();
                    },
                },
                {
                    text: this.$t('updateApp.ok'),
                    handler: () => {
                        if (!this.swRegistration || !this.swRegistration.waiting) return;
                        // Send message to SW to skip the waiting and activate the new SW
                        this.swRegistration.waiting.postMessage({ type: 'SKIP_WAITING' });
                        window.location.reload(true);
                    },
                },
            ],
        });
        return alert.present();
    }

    executeLogout() {
        this.$router.replace({
            name: 'page-login',
        });
    }
}
